<template>
  <div class="contentF">
    <!-- 按钮 -->
    <div class="btns">
      <i class="el-icon-arrow-left"></i>
      <el-button type="text" class="bttn" @click="goBack">返回</el-button>
    </div>
    <!-- 标题 -->
    <div class="text">
      <!-- {{ brand }} {{ type }} 自卸汽车 {{ horsepower + "马力" || "" }} 3.1米 汽油 -->
      <!-- 国五 -->
      {{ brand }}
      {{ type }}
      {{ name }}
      <span v-if="horsepower">{{ horsepower }}马力&nbsp; </span>
      <span v-if="outerLength"
        >{{ (outerLength / 1000).toFixed(2) }}米&nbsp;
      </span>
      {{ fuelType }}
      {{ emissionStandard }}
    </div>
    <!-- 图片区域 -->
    <div class="topBox">
      <div class="top">
        <img style="width: 100%; height: 100%" :src="activeImg" alt />
      </div>
      <div class="rightClick">
        <h3>图片分类</h3>
        <div class="one" v-for="(item, index) in list" :key="index">
          <div
            @click="getAddPush(index)"
            :class="[
              isActiveTitle == index ? 'activeClassTitle waigua' : 'waigua',
            ]"
          >
            {{ item.name }} （{{ item.count }}）
          </div>
          <el-upload
            ref="upload"
            name="image"
            :data="{ vehicleModelId: id, type: item.type }"
            :headers="myHeaders"
            class="avatar-uploader"
            :show-file-list="false"
            action="https://apisgu.chinaucv.com/clientPlatform/upload/uploadImage"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :limit="9"
            :on-exceed="handleExceed"
            :on-success="handleAvatarSuccess"
            :on-progress="timeFil"
            multiple
          >
            <el-button class="btn" type="text">添加</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="down">
      <div @click="leftPage()" class="leftJ">
        <img src="../../assets/zuojiantou.png" alt />
      </div>
      <!-- 图片位置 -->
      <div class="boxBox" v-if="imageList.length != 0">
        <!-- :style="`left:${ww}px`" -->

        <div class="divImgRight" :style="`left:${widthNum}px`">
          <div class="imgBox" v-for="(item, index) in imageList" :key="index">
            <div class="iconPo" @click="delImage(item)">
              <i class="el-icon-circle-close"></i>
            </div>
            <!-- :class="{active: activeName == gameName}" -->
            <img
              :class="[isActive == index ? 'activeClass imggg' : 'imggg']"
              @click="imgClickLeft(index)"
              :src="item.url"
              alt
            />
          </div>
        </div>
        <!-- 1 -->
      </div>
      <div @click="rightPage()" class="rightJ">
        <img src="../../assets/youjiantou.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
var page = 0;
// import { showLoading, hideLoading } from "../../plugins/lo.js";
import { showLoading, hideLoading } from "../../plugins/lo";

export default {
  data() {
    return {
      isActive: 0,
      isActiveTitle: 0,
      activeName: "",
      brand: "", //安徽通博
      type: "", //轻型
      category: "", //专用车
      fuelType: "", //柴油
      emissionStandard: "", //国五
      outerLength:"",
      name: "",
      horsepower: "", //马力
      id: "",
      list: [], //全部的数据
      widthNum: 0, //滚动的位置
      imageList: [], //渲染底部图片的list
      numList: 0,
      activeImg:
        "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png", //渲染大图的
    };
  },
  computed: {},
  created() {
    let token = sessionStorage.getItem("token");
    this.myHeaders = { token };
    console.log(this.myHeaders);
  },
  methods: {
    // 返回
    goBack() {
      this.$router.push("addVehicleOffer");
      console.log("fanhui");
    },
    // 请求接口
    async getList() {
      const { data: res } = await this.$http({
        method: "post",
        url: "api/vehicleOffer/getVehicleImages",
        data: { vehicleModelId: this.$route.query.id.id },
      });
      console.log(res);
      console.log(this.id);
      console.log(res.content); //全部的数据
      this.list = res.content;
      console.log(res.content[0].images); //全部的数据 上装
      this.imageList = res.content[0].images;
      if (res.content[0].images.length == 0) {
        this.activeImg =
          "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png";
      } else {
        this.activeImg = this.imageList[0].url;
      }
    },
    // 右点击
    rightPage() {
      // console.log("111");

      var index = this.imageList.length; //角标
      page = page + 6;
      if (index - page <= 0) {
        page = page - 6;

        console.log("我倒头了");
        return;
      }
      console.log(page);
      this.widthNum = this.widthNum - 908;
      console.log(this.widthNum);
    },
    async towGwtList() {
      const { data: res } = await this.$http({
        method: "post",
        url: "api/vehicleOffer/getVehicleImages",
        data: { vehicleModelId: this.id },
      });
      console.log(res.content); //全部的数据
      this.list = res.content;
      var numList = this.numList;
      this.imageList = res.content[numList].images;
    },
    // 左点击
    leftPage() {
      var index = this.imageList.length; //角标
      page = page - 6;
      if (page < 0) {
        console.log("我倒头了");
        page = page + 6;
        return;
      }
      this.widthNum = this.widthNum + 908;
      console.log(this.widthNum);
    },
    // 点击图片 回显
    imgClickLeft(index) {
      console.log(index);
      this.isActive = index;
      this.activeImg = this.imageList[index].url;
    },
    getAddPush(index) {
      console.log(index);
      this.isActiveTitle = index;
      this.imageList = this.list[index].images;
      this.numList = index;
      console.log(this.imageList);
      if (this.imageList.length == 0) {
        this.activeImg =
          "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png";
      } else {
        this.activeImg = this.imageList[0].url;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    // 图片钩子函数
    async handleAvatarSuccess(res, file, fileList) {
      for (var a = 0; a < 50; a++) {
        hideLoading();
      }
      console.log(res.content.urls[0]);
      let aa = {
        urls: res.content.urls,
        vehicleModelId: res.content.vehicleModelId,
        type: res.content.type,
      };
      console.log(aa);
      //
      const { data: ress } = await this.$http({
        method: "post",
        url: "api/vehicleOffer/addVehicleImages",
        data: aa,
      });
      console.log(ress);
      this.towGwtList();
      setTimeout(() => {
        console.log(this.list);
        var list = this.list;
        if (list[res.content.type - 1].count == 1) {
          if (this.isActiveTitle == res.content.type - 1) {
            console.log(list[res.content.type - 1].images);
            this.activeImg = list[res.content.type - 1].images[0].url;
          }
        }
      }, 1000);
    },
    timeFil(event) {
      console.log(event);
      console.log("进行中");
      showLoading();
      // setTimeout(() => {
      //   console.log("我准备停止")

      //   hideLoading();
      //   console.log("我停止了")
      // }, 4000);
    },
    async delImage(data) {
      console.log(data);
      const { data: res } = await this.$http({
        method: "post",
        url: "api/vehicleOffer/deleteVehicleImages",
        data: { imageIds: [data.id] },
      });
      console.log(res);
      this.towGwtList();
      setTimeout(() => {
        console.log(this.isActiveTitle);
        var list = this.list;
        console.log(list[this.isActiveTitle].count);
        if (list[this.isActiveTitle].count == 0) {
          this.activeImg =
            "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/staff_vehicle_offer_background_20210701.png";
        }
      }, 1000);

      //       this.$confirm("删除后将无法找回，是否确认删除？", "提示", {
      //         confirmButtonText: "删除",
      //         cancelButtonText: "取消",
      //         type: "warning",
      //       })
      //         .then(async () => {
      //          const { data: res } = await this.$http({
      //              method: "post",
      //         url: "api/vehicleOffer/deleteVehicleImages",
      //         data: { imageIds: [data.id] },
      //           });
      //           if (res.code != 200) return this.message.error("错误");
      //           this.$message.success("删除成功");
      //           // this.getList();
      //         })
      //         .catch(() => {
      //           this.$message({
      //             type: "info",
      //             message: "已取消删除",
      //           });
      //         });
      // this.$confirm("删除后将无法找回，是否确认删除？", "提示", {
      //   confirmButtonText: "删除",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(async () => {
      //     const { data: res } = await this.$http({
      //       method: "post",
      //       url: "api/vehicleOffer/deleteVehicleImages",
      //       data: { vehicleModelId: [...data.id] },
      //     });
      //     console.log(res);
      //     // if (res.code != 200) return this.message.error("错误");
      //     this.$message.success("删除成功");
      //     // this.getList();
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
      // this.$confirm("此操作将永久删除该图片, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(async () => {
      //     const { data: res } = await this.$http({
      //       method: "post",
      //       url: "api/vehicleOffer/deleteVehicleImages",
      //       data: { vehicleModelId: [...data.id] },
      //     });
      //     console.log(res);
      //   })
      //   .catch(() => {
      //     console.log("失败");
      //   });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
  mounted(data) {
    this.getList();
    console.log(this.$route.query.id);
    //     dmCode: 110100
    // area: "北京市"
    // brand: "爱瑞特"
    // cargoBoxLength: null
    // category: "专用车"
    // driveType: "4×2"
    // emissionStandard: "零排放"
    // engineBrand: ""
    // engineModel: "TZ210XSD42"
    // fuelType: "纯电动"
    // guidePrice: 11.34
    // guidePriceOffer: null
    // horsepower: 80
    // id: 48335
    // imageCount: 1
    // imageUrl: "https://vehicle-library.oss-cn-beijing.aliyuncs.com/cada-pic/cn357/dbf2954705f94850bef35c55693ea84b.jpg"
    // model: "ART5030TYHQ26BEV"
    // name: "纯电动路面养护车"
    // outerLength: 5160
    // recentPrice: 9.64
    // recentPriceOffer: null
    // speedRatio: null
    // type: "轻型"
    this.id = this.$route.query.id.id;
    // let  indexLista = this.$route.query.id;
    this.brand = this.$route.query.id.brand;
    this.type = this.$route.query.id.type;
    this.category = this.$route.query.id.category;
    this.fuelType = this.$route.query.id.fuelType;
    this.horsepower = this.$route.query.id.horsepower;
    this.outerLength = this.$route.query.id.outerLength;
    this.emissionStandard = this.$route.query.id.emissionStandard;
    this.name = this.$route.query.id.name;
    // this.fuelType = this.$route.query.id.fuelType;

    //  brand:"",  //安徽通博
    //     type:"", //轻型
    //     category:"",//专用车
    //     fuelType:"",//柴油
    //     emissionStandard:"",//国五
    //    seelook(data) {
    //   console.log(data);
    //   //  sessionStorage.setItem("seelook",JSON.stringify(data));
    //   //  this.$router.push("/memberManagementLook?index=1")
    //   this.$router.push({
    //     path: "/memberManagementLook?index=2",
    //     query: { id: data },
    //   });
    // },
  },
  // beforeRouteLeave(to, from, next) {
  //   // if (to.path == "/addVehicleOffer") {
  //   //   to.meta.keepAlive = true;

  //   // }
  //   next();
  // },
};
</script>

<style scoped lang="less">
.contentF {
  width: 100%;
}
.topBox {
  width: 100%;
  height: 476px;
  // background: #000;
  margin-top: 20px;
}
.top {
  width: 635px;
  height: 476px;
  background: #ffffff;
  float: left;
}
.rightClick {
  float: left;
  margin-left: 15px;
  width: 255px;
  height: 476px;
  background: #ffffff;
  opacity: 1;
}
.waigua {
  width: 133px;
  height: 26px;
  // background: #000;
  float: left;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  opacity: 1;
}
.btn {
  float: left;
  margin-left: 10px;
}
.down {
  width: 908px;
  height: 120px;
  margin-top: 10px;
  position: relative;

  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  // width: 100%;
  // padding: 20px 0px;
}
// <div class="one">外观   </div>
//   <div class="one">仪表与内饰</div>
//   <div class="one">底盘</div>
//   <div class="one">动力</div>
//   <div class="one">上装</div>
//   <div class="one">其他</div>
.one {
  width: 200px;
  height: 30px;
  margin-top: 11px;
  margin-left: 20px;
  cursor: pointer;
}
h3 {
  margin-left: 20px;
}
.text {
  width: 100%;
  height: 25px;
  // background: orange;
  // margin-top: 15px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 25px;
  color: #333333;
  opacity: 1;
}
.bttn {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 25px;
  color: #666666;
  opacity: 1;
}
.leftJ {
  position: absolute;
  top: 30px;
  z-index: 11;
  right: 890px;
}
.rightJ {
  position: absolute;
  top: 30px;
  left: 911px;
  z-index: 11;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.medium {
  width: 100px;
  height: 50px;
}
.lunbo {
  z-index: 1;
}
.btn {
  margin-top: -7px;
}
.imgH {
  width: 128px;
  height: 96px;
  border: 1px solid lightcoral;
  float: left;
  margin-left: 10px;
}
.boxBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 10px 0;
}
.imgBox {
  position: relative;
  box-sizing: border-box;
  width: 128px;
  // height: 96px;
  background: #dbdbdb;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  margin-left: 20px;
  float: left;
}
// .imgBox > img {
//   width: 99%;
//   height: 99%;
// }
.imggg {
  width: 128px;
  height: 96px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.divImgRight {
  min-width: 100vw;
  height: 100%;
  position: absolute;
  margin-left: 35px;
}
.imgBox:nth-child(1) {
  margin: 0;
}
.iconPo {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}
.activeClass {
  border: 2px solid #1883e3;
  box-sizing: border-box;
}
.activeClassTitle {
  color: #1883e3;
}
</style>
